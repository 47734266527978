
import Chip from '@material-ui/core/Chip'
import parse from 'html-react-parser'
import { TableFilterList } from 'mui-datatables'
import React from 'react'
import { Alert, Badge, Button as BSButton, Col, ListGroup, Modal, Row } from 'react-bootstrap'
import Carousel from 'react-bootstrap/Carousel'
import styled from 'styled-components'
import filePng from '../../images/file.png'
import AnnounceAwardsLotModal from '../../pages/app/kitchensink/vendorAnnounceAwardsLotModal'
import AnnounceAwardsSkuModal from '../../pages/app/kitchensink/vendorAnnounceAwardsSkuModal'
import SelectAlternativeBidLotModal from '../../pages/app/kitchensink/vendorSelectAlternativeBidLotModal'
import SelectAlternativeBidSkuModal from '../../pages/app/kitchensink/vendorSelectAlternativeBidSkuModal'
import UpdateBenchmarkLotModal from '../../pages/app/kitchensink/vendorUpdateBenchmarkLotModal'
import UpdateBenchmarkSkuModal from '../../pages/app/kitchensink/vendorUpdateBenchmarkSkuModal'
import UploadBenchmarkModal from '../../pages/app/kitchensink/vendorUploadBenchmarkModal'
import { useGetCountriesQuery, useGetCurrenciesQuery, useGetListingParticipants, useGetListingQuery, useGetListingStatusesQuery, useGetuserDetailsQuery, useTemporaryAwardsLotMutation, useTemporaryAwardsSkuMutation } from '../api'
import { useDownloadUnitPricesQuery, useGetListingLotSkusQuery } from '../api/BuyerApi'
import { downloadGradingStandards } from '../api/DownloadsApi'
import LotNavButtons from '../biddingActivityTabs/LotNavButtons'
import Countdown from '../Countdown'
import { CREATE_LISTING_TAB_PAGES } from '../editListingTabs/ListingPageIndexes'
import { SimpleTabs } from '../Form'
import { hideNull } from '../hideNull'
import MUIDataTable, { headerRightAlign } from '../MuiDatatableUtils'
import { SelectToolbarWithoutUnitPrices, SelectToolbarWithUnitPrices } from '../MuiSelectToolbarAwardMoreActions'
import MuiToolbarUploadSubmit from '../MuiToolbarUploadSubmit'
import { vendorNavTo } from '../navigation'
import { skuToDescription } from '../skuToDescription'
import Spinner from '../spinner'
import { getLabelFromArray, getLabelFromObject, OPTS_AWARDLEVEL, OPTS_BIDLEVEL, OPTS_BIDSMANDATORY, OPTS_SALETYPESELECTION, OPTS_SHOWASKING, OPTS_TIMEEXTENSION } from '../static'
import { downloadDataString, quickAndDirtyCSVDownloadLink } from '../utils'
import { useWatchListingSocket, WebSocketStatus } from '../websocket'

const Button = styled(BSButton)`
.spinner-border {
  width: 14px;
  height: 14px;
}
min-width:95px;
`

const CustomChip = ({ label, onDelete }) => {
  return (
        <Chip
            variant="outlined"
            color="secondary"
            label={label}
            onDelete={onDelete}
        />
  )
}

const CustomFilterList = (props) => {
  return <TableFilterList {...props} ItemComponent={CustomChip} />
}

/**
 * Returns true if awardLevelSelection is 'award_sku.
 */
const isAwardLevelSelectionASku = (awardLevelSelection) => awardLevelSelection === 'award_sku'

/**
 *
 * ANCHOR Page
 *
 * @param {*} props
 * @returns
 */
const Page = (props) => {
  const { listingId } = props
  const userDetailsQuery = useGetuserDetailsQuery()
  const { data: { _isVendor = false } = {} } = userDetailsQuery

  // ANCHOR query
  const listingQuery = useGetListingQuery({ listingId, isVendor: true }) // N.B. from this page, we are always the vendor!
  const {
    isLoading = false, data: {
      end_time: endTime = null,
      listing_lots: listingLots = [],
      award_level_selection: awardLevelSelection = null,
      bid_level_selection: bidLevelSelection = null
    } = {}
  } = listingQuery
  const listingParticipantsQuery = useGetListingParticipants(listingId)
  const statusesQuery = useGetListingStatusesQuery()
  const countriesQuery = useGetCountriesQuery()
  const currenciesQuery = useGetCurrenciesQuery()
  const announceAwardsLotMutation = useTemporaryAwardsLotMutation()
  const announceAwardsSkuMutation = useTemporaryAwardsSkuMutation()

  const { data: participants = [] } = listingParticipantsQuery
  /** @type {Object.<string, Buyer} */
  const participantsById = participants.reduce((prev, curr) => {
    prev[(curr.company_id).toString()] = curr
    return prev
  }, {})

  // ANCHOR react-state
  const [wsstatus, setWsstatus] = React.useState('connecting')
  const [selectedLotId, setSelectedLotId] = React.useState('all')
  const [selectedLot, setSelectedLot] = React.useState({})
  const [selectedLots, setSelectedLots] = React.useState([])
  const [selectedSkus, setSelectedSkus] = React.useState([])
  const [selectedRowIndexes, setSelectedRowIndexes] = React.useState([])
  const [downloadLotUnitPricesId, setDownloadLotUnitPricesId] = React.useState(null)

  const deselectAll = () => {
    setSelectedRowIndexes([])
  }

  // ANCHOR more query
  const listingLotSkusQuery = useGetListingLotSkusQuery({ listingId, listingLotId: selectedLotId, listingLots, isVendor: true })
  const { data: { listing_lot: listingLot = {} } = {} } = listingLotSkusQuery
  const { listing_lot_skus: listingLotSkus = [] } = listingLot

  // Download unit prices (for LOT only...)
  const doDownloadUnitPrices = (lot) => {
    const headers = [
      'Lot Number',
      'SKU ID',
      'Description',
      'Grade',
      'Quantity',
      'Unit Price'
    ]
    const body = lot.listing_lot.listing_lot_skus.map(sku => [
      sku.listing_lot_number,
      sku.listing_lot_sku_id,
      skuToDescription(sku),
      hideNull(sku.grade),
      hideNull(sku.qty),
      hideNull(sku.unit_price)
    ])
    downloadDataString(quickAndDirtyCSVDownloadLink(headers, body), `unitprices-listing-${listingId}-lot-${lot.id}.csv`)
  }

  // Download sku as csv...
  const downloadUnitPricesQuery = useDownloadUnitPricesQuery({ listingId, listingLotId: downloadLotUnitPricesId, isVendor: true })
  React.useEffect(() => {
    if (downloadUnitPricesQuery.isSuccess && downloadLotUnitPricesId !== null) {
      doDownloadUnitPrices(downloadUnitPricesQuery.data)
      setDownloadLotUnitPricesId(null) // reset download
    }
  }, [downloadLotUnitPricesId, downloadUnitPricesQuery])

  const getSkuRow = (meta) => {
    return listingLotSkus[meta.currentTableData[meta.rowIndex].index]
  }

  // connect for socket updates...
  useWatchListingSocket({ listingId, handleStatusUpdate: setWsstatus, refreshEntireListing: true, isVendor: true })

  // ANCHOR modal's states
  const [showAdditionalNotesModal, setShowAdditionalNotesModal] = React.useState(false)
  const [showParticipantsModal, setShowParticipantsModal] = React.useState(false)
  const [showBiddingIncrementsModal, setShowBiddingIncrementsModal] = React.useState(false)
  const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] = React.useState(false)
  const [showSamplePhotosModal, setShowSamplePhotosModal] = React.useState(false)
  const [showUploadBenchmarkModal, setShowUploadBenchmarkModal] = React.useState(false)
  const [showUpdateBenchmarkLotModal, setShowUpdateBenchmarkLotModal] = React.useState(false)
  const [showUpdateBenchmarkSkuModal, setShowUpdateBenchmarkSkuModal] = React.useState(false)
  const [showAnnounceAwardsLotModal, setShowAnnounceAwardsLotModal] = React.useState(false)
  const [showAnnounceAwardsSkuModal, setShowAnnounceAwardsSkuModal] = React.useState(false)
  const [showSelectAlternativeBidLotModal, setShowSelectAlternativeBidLotModal] = React.useState(false)
  const [showSelectAlternativeBidSkuModal, setShowSelectAlternativeBidSkuModal] = React.useState(false)

  // ANCHOR variables
  // eslint-disable-next-line camelcase
  const { data: { time_extension_selection = 'enabled', sale_type_selection } = {} } = listingQuery
  // eslint-disable-next-line camelcase
  const timeExtensionEnabled = time_extension_selection === 'enabled'
  // eslint-disable-next-line camelcase
  const isTender = sale_type_selection === 'tender'

  const isDeltaValid = (delta) => !isNaN(delta) && delta !== 0
  const isDeltaPositive = (delta) => delta >= 0
  const isDef = (o) => typeof o !== 'undefined' && o !== null

  // ANCHOR LOT columns
  const columnsLot = [
    timeExtensionEnabled
      ? {
          label: 'End Time',
          name: 'end_time',
          options: { customBodyRender: (value) => value ? <Countdown className="text-nowrap" toDate={new Date(value)} /> : '-' }
        }
      : null,
    { label: 'Lot', name: 'lot_number' },
    { label: 'Description', name: 'lot_description' },
    { label: 'Quantity', name: 'quantity' },
    {
      label: 'Benchmark',
      name: 'benchmark',
      options: {
        customHeadLabelRender: headerRightAlign,
        customBodyRender: (benchmark) => <div className="text-right">{benchmark ? ('$' + Math.round(benchmark).toLocaleString()) : ''}</div>
      }
    },
    {
      label: 'Delta',
      name: 'delta',
      options: {
        customHeadLabelRender: headerRightAlign,
        customBodyRender: (delta) => <div className={`d-flex justify-content-between align-items-center delta delta-${isDeltaPositive(delta) ? 'green' : 'red'}`}>
          {
          isDeltaValid(delta)
            ? <>
              <span className="text-right flex-grow-1">${Math.abs(Math.round(delta)).toLocaleString()}</span>
              <span className={`fas fa-caret-${isDeltaPositive(delta) ? 'up' : 'down'} ml-2`}></span>
              </>
            : ''
          }
        </div>
      }
    },
    {
      label: 'Highest Bid',
      name: 'highest_bid',
      options: {
        customHeadLabelRender: headerRightAlign,
        _csvRender: (row) => {
          const { selected_buyer_bid: selectedBid, highest_bid: highestBid } = row
          return isDef(selectedBid) ? selectedBid : isDef(highestBid) ? highestBid : 0
        },
        customBodyRender: (ignored, meta) => {
          const { selected_buyer_company_id: selectedBuyerId, highest_company_id: highestBuyerId, selected_buyer_bid: selectedBid, highest_bid: highestBid } = listingLots[meta.rowIndex]
          const isTemp = typeof selectedBuyerId === 'string' && selectedBuyerId.startsWith('tmp-')
          const parsedSelectedBuyerId = isTemp && selectedBuyerId.startsWith('tmp-') ? selectedBuyerId.substring(4) : `${selectedBuyerId}`
          const colour = selectedBuyerId === null ? 'green' : isTemp ? 'grey' : (parsedSelectedBuyerId === `${highestBuyerId}` ? 'green' : 'purple')
          return <>
            <div className={`bidstatusindicator bidstatusindicator-${colour} text-right`}
            >{`$${Math.round(isDef(selectedBid) ? selectedBid : isDef(highestBid) ? highestBid : 0).toLocaleString()}`}</div>
          </>
        }
      }
    },
    {
      label: 'Participant',
      name: 'lot_number',
      options: {
        _csvRender: (row) => {
          const { selected_buyer_company_id: selectedBuyerId, highest_company_id: highestBuyerId } = row
          const isTemp = typeof selectedBuyerId === 'string' && selectedBuyerId.startsWith('tmp-')
          const parsedSelectedBuyerId = isTemp && selectedBuyerId.startsWith('tmp-') ? selectedBuyerId.substring(4) : `${selectedBuyerId}`
          const companyId = selectedBuyerId !== null ? parsedSelectedBuyerId : isDef(highestBuyerId) ? highestBuyerId?.toString() : null
          return (participantsById[companyId] || {}).name
        },
        customBodyRender: (lotNumber, meta) => {
          const { selected_buyer_company_id: selectedBuyerId, highest_company_id: highestBuyerId } = listingLots[meta.rowIndex]
          const isTemp = typeof selectedBuyerId === 'string' && selectedBuyerId.startsWith('tmp-')
          const parsedSelectedBuyerId = isTemp && selectedBuyerId.startsWith('tmp-') ? selectedBuyerId.substring(4) : `${selectedBuyerId}`
          const companyId = selectedBuyerId !== null ? parsedSelectedBuyerId : isDef(highestBuyerId) ? highestBuyerId?.toString() : null
          return (participantsById[companyId] || {}).name
        }
      }
    },
    {
      label: 'Award',
      name: 'lot_award_status',
      options: {
        customBodyRender: (award, meta) => {
          const isTemp = typeof award === 'string' && award.startsWith('tmp-')
          const value = award ? (isTemp ? award.substring(4).toLowerCase() : award) : ''
          const isAccept = value.startsWith('accept')
          const isDecline = value.startsWith('decline')
          const label = award ? (isAccept ? 'accept' : isDecline ? 'decline' : '') : ''
          return isTemp
            ? <Badge pill variant="outline-dark" className="text-capitalize">{label}</Badge>
            : isAccept
              ? <Badge pill variant="outline-success" className="text-capitalize">{label}</Badge>
              : isDecline
                ? <Badge pill variant="outline-danger" className="text-capitalize">{label}</Badge>
                : null
        }
      }
    }, !isTender
      ? {
          label: 'Unit Price',
          name: 'lot_unit_price_status',
          options: {
            customBodyRender: (status, meta) => {
              const lot = listingLots[meta.rowIndex]
              const isTemp = typeof status === 'string' && status.startsWith('tmp-')
              const isReceived = status === 'received'
              const value = status ? (isTemp ? status.substring(4).toLowerCase() : status) : ''
              return isReceived
                ? <Button variant="outline-primary" size="sm" disabled={listingLotSkusQuery.isFetching} onClick={() => setDownloadLotUnitPricesId(lot.id)}>
              { listingLotSkusQuery.isFetching ? <Spinner /> : 'Download'}
            </Button>
                : isTemp
                  ? <Badge pill variant="outline-dark" className="text-capitalize">{value}</Badge>
                  : <Badge pill variant="outline-warning" className="text-capitalize">{value}</Badge>
            }
          }
        }
      : null
  ].filter(f => f !== null)

  // ANCHOR SKU columns
  const columnsSku = [
    listingQuery.isSuccess && listingQuery.data.time_extension_selection === 'enabled'
      ? {
          label: 'End Time',
          name: 'listing_lot_sku_id',
          options: {
            customBodyRender: (x, meta) => {
              const data = getSkuRow(meta)
              if (data.end_time) {
                return <Countdown
                  className="text-nowrap"
                  toDate={new Date(data.end_time)}
                  showOnEnd=''
                />
              } else {
                return 'No End Time?'
              }
            }
          }
        }
      : null,
    // { label: 'Id', name: 'id' },
    {
      label: 'Lot',
      name: 'listing_lot_number'
    },
    {
      label: 'Description',
      name: 'sku_description'
      // options: {
      //   customBodyRender: (val, meta) => {
      //     const data = getSkuRow(meta)
      //     return skuToDescription(data)
      //   }
      // }
    },
    { label: 'Make', name: 'make', options: { display: false } },
    { label: 'Model', name: 'model', options: { display: false } },
    { label: 'Memory', name: 'memory', options: { display: false } },
    { label: 'Colour', name: 'colour', options: { display: false } },
    { label: 'Carrier', name: 'carrier', options: { display: false } },
    { label: 'Id lock', name: 'id_lock', options: { display: false } },
    { label: 'Kit', name: 'kit', options: { display: false } },
    { label: 'Region lock', name: 'region_lock', options: { display: false } },
    { label: 'Sim lock', name: 'sim_lock', options: { display: false } },
    { label: 'Grade', name: 'grade' },
    { label: 'Quantity', name: 'qty' },
    // Benchmark, Delta, Highest Bid, Participant, Award
    {
      label: 'Benchmark',
      name: 'benchmark',
      options: {
        customHeadLabelRender: headerRightAlign,
        customBodyRender: (benchmark) => <div className="text-right">{benchmark ? ('$' + Math.round(benchmark).toLocaleString()) : ''}</div>
      }
    },
    {
      label: 'Delta',
      name: 'delta',
      options: {
        customHeadLabelRender: headerRightAlign,
        customBodyRender: (delta) => <div className={`d-flex justify-content-between align-items-center delta delta-${isDeltaPositive(delta) ? 'green' : 'red'}`}>
          {
          isDeltaValid(delta)
            ? <>
              <span className="text-right flex-grow-1">${Math.abs(Math.round(delta)).toLocaleString()}</span>
              <span className={`fas fa-caret-${isDeltaPositive(delta) ? 'up' : 'down'} ml-2`}></span>
              </>
            : ''
          }
        </div>
      }
    },
    {
      label: 'Highest Bid',
      name: 'highest_bid',
      options: {
        customHeadLabelRender: headerRightAlign,
        _csvRender: (sku) => {
          const { selected_buyer_bid: selectedBid, highest_bid: highestBid } = sku
          return selectedBid !== null ? selectedBid : highestBid
        },
        customBodyRender: (x, meta) => {
          const sku = getSkuRow(meta)
          const { selected_buyer_company_id: selectedBuyerId, highest_company_id: highestBuyerId, selected_buyer_bid: selectedBid, highest_bid: highestBid } = sku
          // return <div>{JSON.stringify({ selectedBuyerId, highestBuyerId, selectedBid, highestBid })}</div>
          const isTemp = typeof selectedBuyerId === 'string' && selectedBuyerId.startsWith('tmp-')
          const parsedSelectedBuyerId = isTemp && selectedBuyerId.startsWith('tmp-') ? selectedBuyerId.substring(4) : `${selectedBuyerId}`
          const colour = selectedBuyerId === null ? 'green' : isTemp ? 'grey' : (parsedSelectedBuyerId === `${highestBuyerId}` ? 'green' : 'purple')
          return <>
            <div className={`bidstatusindicator bidstatusindicator-${colour} text-right`}
            >{`$${Math.round(selectedBid !== null ? selectedBid : highestBid).toLocaleString()}`}</div>
          </>
        }
      }
    },
    {
      label: 'Subtotal',
      name: 'subtotal',
      options: {
        _csvRender: (sku) => {
          const { selected_buyer_bid: selectedBid, highest_bid: highestBid } = sku
          return Math.round(selectedBid !== null ? selectedBid : highestBid) * sku.qty
        },
        customHeadLabelRender: headerRightAlign,
        customBodyRender: (x, meta) => {
          const sku = getSkuRow(meta)
          const { selected_buyer_bid: selectedBid, highest_bid: highestBid } = sku
          return <div className="text-right">${Math.round(selectedBid !== null ? selectedBid : highestBid) * sku.qty}</div>
        }
      }
    },
    {
      label: 'Participant',
      name: 'lot_number',
      options: {
        _csvRender: (sku) => {
          // const sku = getSkuRow(meta)
          const { selected_buyer_company_id: selectedBuyerId, highest_company_id: highestBuyerId } = sku
          const isTemp = typeof selectedBuyerId === 'string' && selectedBuyerId.startsWith('tmp-')
          const parsedSelectedBuyerId = isTemp && selectedBuyerId.startsWith('tmp-') ? selectedBuyerId.substring(4) : `${selectedBuyerId}`
          const companyId = selectedBuyerId !== null ? parsedSelectedBuyerId : isDef(highestBuyerId) ? highestBuyerId?.toString() : null
          return (participantsById[companyId] || {}).name
        },
        customBodyRender: (lotNumber, meta) => {
          const sku = getSkuRow(meta)
          const { selected_buyer_company_id: selectedBuyerId, highest_company_id: highestBuyerId } = sku
          const isTemp = typeof selectedBuyerId === 'string' && selectedBuyerId.startsWith('tmp-')
          const parsedSelectedBuyerId = isTemp && selectedBuyerId.startsWith('tmp-') ? selectedBuyerId.substring(4) : `${selectedBuyerId}`
          const companyId = selectedBuyerId !== null ? parsedSelectedBuyerId : isDef(highestBuyerId) ? highestBuyerId?.toString() : null
          return (participantsById[companyId] || {}).name
        }
      }
    },
    {
      label: 'Award',
      name: 'lot_award_status',
      options: {
        customBodyRender: (award, meta) => {
          const isTemp = typeof award === 'string' && award.startsWith('tmp-')
          const value = award ? (isTemp ? award.substring(4).toLowerCase() : award) : ''
          const isAccept = value.startsWith('accept')
          const isDecline = value.startsWith('decline')
          const label = award ? (isAccept ? 'accept' : isDecline ? 'decline' : '') : ''
          return isTemp
            ? <Badge pill variant="outline-dark" className="text-capitalize">{label}</Badge>
            : isAccept
              ? <Badge pill variant="outline-success" className="text-capitalize">{label}</Badge>
              : isDecline
                ? <Badge pill variant="outline-danger" className="text-capitalize">{label}</Badge>
                : null
        }
      }
    }
  ].filter(f => f !== null)

  // ANCHOR React begin <>
  return <>

              <div className="card-body rounded-soft bg-white">
                {/* show spinner if loading, otherwise show content */}
                { listingQuery.isLoading || statusesQuery.isLoading || countriesQuery.isLoading || currenciesQuery.isLoading
                  ? <div className="d-flex justify-content-center p-5"><Spinner /></div>
                  : listingQuery.isError
                    ? (<Alert variant="danger" className="my-4">{listingQuery.error.message}</Alert>)
                    : listingQuery.isSuccess && (
                    <div className="container-fluid mb-4">
                      <Row>

                        {/* details */}
                        <Col md={12} lg={5}>
                          <div className="rh-bodyheader">Details</div>
                          <ListGroup>
                            {isTender
                              ? ([
                                  ['End Time', <Countdown key="endtime" toDate={new Date(listingQuery.data.end_time)} />],
                                  ['Reference', listingQuery.data.listing_reference],
                                  ['Origin', getLabelFromArray(countriesQuery.data, listingQuery.data.country)],
                                  ['Currency', getLabelFromArray(currenciesQuery.data, listingQuery.data.currency)],
                                  ['Total Lots', (typeof listingQuery.data.total_lot_count === 'undefined' || listingQuery.data.total_lot_count === null) ? '-' : (listingQuery.data.total_lot_count).toLocaleString()],
                                  ['Total Units', (typeof listingQuery.data.total_item_count === 'undefined' || listingQuery.data.total_item_count === null) ? '-' : (listingQuery.data.total_item_count).toLocaleString()],
                                  ['Participants', <Button key="participants" variant="outline-primary" size="sm" onClick={() => setShowParticipantsModal(true)}>View</Button>]
                                ].map((val) => {
                                  if (val) {
                                    const [label, value] = val
                                    return <ListGroup.Item key={label}><b>{label}</b>{value}</ListGroup.Item>
                                  }
                                  return undefined
                                }
                                ))
                              : (
                                  [
                                    ['End Time', <Countdown key="endtime" toDate={new Date(listingQuery.data.end_time)} />],
                                    ['Reference', listingQuery.data.listing_reference],
                                    ['Origin', getLabelFromArray(countriesQuery.data, listingQuery.data.country)],
                                    ['Currency', getLabelFromArray(currenciesQuery.data, listingQuery.data.currency)],
                                    ['Total Lots', (typeof listingQuery.data.total_lot_count === 'undefined' || listingQuery.data.total_lot_count === null) ? '-' : (listingQuery.data.total_lot_count).toLocaleString()],
                                    ['Total Units', (typeof listingQuery.data.total_item_count === 'undefined' || listingQuery.data.total_item_count === null) ? '-' : (listingQuery.data.total_item_count).toLocaleString()],
                                    ['Participants', <Button key="participants" variant="outline-primary" size="sm" onClick={() => setShowParticipantsModal(true)}>View</Button>]
                                  ].map((val) => {
                                    if (val) {
                                      const [label, value] = val
                                      return <ListGroup.Item key={label}><b>{label}</b>{value}</ListGroup.Item>
                                    }
                                    return undefined
                                  }
                                  )
                                )}
                          </ListGroup>
                        </Col>

                        {/* format */}
                        <Col md={12} lg={5}>
                          <div className="rh-bodyheader">Format</div>
                          <ListGroup>
                            {isTender
                              ? (
                                  [
                                    ['Sale Type', getLabelFromObject(OPTS_SALETYPESELECTION, listingQuery.data.sale_type_selection)],
                                    ['Award Level', getLabelFromObject(OPTS_AWARDLEVEL, listingQuery.data.award_level_selection)],
                                    ['Bid Level', getLabelFromObject(OPTS_BIDLEVEL, listingQuery.data.bid_level_selection)],
                                    ['Bids Mandatory on All Lines', getLabelFromObject(OPTS_BIDSMANDATORY, listingQuery.data.bids_mandatory_selection)]
                                  ].map(([label, value]) =>
                              <ListGroup.Item key={label}><b>{label}</b>{value}</ListGroup.Item>
                                  ))
                              : (
                                  [
                                    ['Sale Type', getLabelFromObject(OPTS_SALETYPESELECTION, listingQuery.data.sale_type_selection)],
                                    ['Time Extension', getLabelFromObject(OPTS_TIMEEXTENSION, listingQuery.data.time_extension_selection)],
                                    ['Award Level', getLabelFromObject(OPTS_AWARDLEVEL, listingQuery.data.award_level_selection)],
                                    ['Bid Level', getLabelFromObject(OPTS_BIDLEVEL, listingQuery.data.bid_level_selection)],
                                    ['Show Asking Bid', getLabelFromObject(OPTS_SHOWASKING, listingQuery.data.show_asking_bid_selection)],
                                    ['Bid Increments', <Button key="foo" variant="outline-primary" size="sm" onClick={() => setShowBiddingIncrementsModal(true)}>View</Button>],
                                    ['Bids Mandatory on All Lines', getLabelFromObject(OPTS_BIDSMANDATORY, listingQuery.data.bids_mandatory_selection)]
                                  ].map(([label, value]) =>
                                <ListGroup.Item key={label}><b>{label}</b>{value}</ListGroup.Item>
                                  ))
                          }
                          </ListGroup>
                        </Col>

                        {/* supplements */}
                        { listingQuery.isSuccess && (
                          <Col md={12} lg={2}>
                            <div className="rh-bodyheader">Supplements</div>
                            {/* product manifest */}
                            <div>
                              <Button
                                variant="outline-primary"
                                className="shadow mb-3"
                                onClick={() => vendorNavTo.productManifestPage(listingId)}
                                // disabled={!listingQuery.data.product_manifest_file_url}
                                // onClick={() => download(listingQuery.data.product_manifest_file_url)}
                              >
                                Product Manifest
                              </Button>
                            </div>
                              {/* communications */}
                            <div>
                              <Button
                                variant="outline-primary"
                                className="shadow mb-3"
                                onClick={() => vendorNavTo.productCommunications(listingId)}
                              >
                                Communications
                              </Button>
                            </div>
                            {/* Listing notes
                            <div>
                              <Button
                                variant="outline-primary"
                                className="shadow mb-3"
                                disabled={typeof listingQuery.data.buyer_notes !== 'string' || listingQuery.data.buyer_notes.trim().length === 0}
                                onClick={() => setShowAdditionalNotesModal(true)}
                            >
                              Listing Notes
                            </Button>
                            </div>
                            */}
                            {/* terms and conditions */}
                            <div>
                              <Button
                                variant="outline-primary"
                                className="shadow mb-3"
                                disabled={typeof listingQuery.data.terms_and_conditions !== 'string' || listingQuery.data.terms_and_conditions.trim().length === 0}
                                onClick={() => setShowTermsAndConditionsModal(true)}
                            >
                              Terms and Conditions
                            </Button>
                            </div>

                            {/* sample phots */}{ !listingQuery.data.sample_photo_files
                              ? <Button
                              variant="outline-primary"
                              className="shadow mb-3"
                              disabled={true}
                              onClick={() => setShowSamplePhotosModal(true)}
                          >
                          Attachments
                          </Button>
                              : <div>
                            <Button
                              variant="outline-primary"
                              className="shadow mb-3"
                              disabled={ listingQuery.data.sample_photo_files.filter(p => p !== 'undefined').length === 0 }
                              onClick={() => setShowSamplePhotosModal(true)}
                          >
                          Attachments
                          </Button>
                          </div>
                        }
                            {/* grading standard */}
                            <div><Button variant="outline-primary" className="shadow mb-3" onClick={() => downloadGradingStandards()}>Grading Standard</Button></div>
                          </Col>
                        )}

                      </Row>

                      {/* summary table */}
                      <div className="rh-bodyheader border-bottom mt-3">Summary</div>
                      <SimpleTabs elements={[{ label: 'Overview' }]} selectedIndex={0} size="sm" />
                      <div className="mt-3">

                        {/* lot buttons */}

                        { isAwardLevelSelectionASku(awardLevelSelection) && <LotNavButtons
                          listingQuery={listingQuery}
                          selectedLotId={selectedLotId}
                          setSelectedLotId={setSelectedLotId}
                          setSelectedLot={setSelectedLot}
                        />}

                        <WebSocketStatus status={wsstatus} />

                        {/* ANCHOR TENDER LOT table */}
                        { isTender && !isAwardLevelSelectionASku(awardLevelSelection) && listingQuery.isLoading && <div className="d-flex justify-content-center p-5"><Spinner /></div>}
                        { isTender && !isAwardLevelSelectionASku(awardLevelSelection) && listingQuery.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'' + listingLotSkusQuery.error.message}</Alert> }
                        { isTender && !isAwardLevelSelectionASku(awardLevelSelection) && listingQuery.isSuccess && <MUIDataTable
                                data={listingLots}
                                columns={columnsLot}
                                options={{
                                  pagination: true,
                                  jumpToPage: false,
                                  rowsPerPage: 100,
                                  print: false,
                                  search: true,
                                  download: true,
                                  sort: false,
                                  filter: true,
                                  viewColumns: true,
                                  elevation: 0,
                                  components: {
                                    TableFilterList: CustomFilterList
                                  },
                                  onDownload: (buildHead, buildBody, columns, data) => {
                                    // Use _csvRender functions to override data...
                                    console.log(columns)
                                    columns.forEach((col, cidx) => {
                                      if (typeof col._csvRender === 'function') {
                                        data.forEach((row, ridx) => { row.data[cidx] = col._csvRender(listingLots[ridx]) })
                                      }
                                    })
                                    return '\uFEFF' + buildHead(columns) + buildBody(data)
                                  },
                                  setTableProps: () => ({ size: 'small' }),
                                  customToolbarSelect: selectedRows => {
                                    // TODO This should be lazy loaded for better performance, see SKU below for example.
                                    const lots = selectedRows.data.map(d => listingLots[d.dataIndex])
                                    const ids1 = selectedLots.map(l => l.id).join(',')
                                    const ids2 = lots.map(l => l.id).join(',')
                                    // only update if the selection has changed...
                                    // console.debug('check', { update: ids1 !== ids2, ids1, ids2 })
                                    if (ids1 !== ids2) setSelectedLots([...lots])
                                    return (
                                    <SelectToolbarWithoutUnitPrices
                                      onAwardAccept={() => {
                                        announceAwardsLotMutation.mutate({
                                          listingId,
                                          awardAction: 'accept_without_unit_price',
                                          lots
                                        })
                                      }}
                                      // onAwardAcceptWithoutUnitPrice={() => {
                                      //   announceAwardsLotMutation.mutate({
                                      //     listingId,
                                      //     awardAction: 'accept_without_unit_price',
                                      //     lots
                                      //   })
                                      // }}
                                      onAwardDecline={() => {
                                        announceAwardsLotMutation.mutate({
                                          listingId,
                                          awardAction: 'decline',
                                          lots
                                        })
                                      }}
                                      onUpdateBenchmarks={() => setShowUpdateBenchmarkLotModal(true)}
                                      onSelectAlternativeBid={() => setShowSelectAlternativeBidLotModal(true)}
                                      onClear={() => {
                                        announceAwardsLotMutation.mutate({
                                          listingId,
                                          awardAction: 'clear',
                                          lots
                                        })
                                      }}
                                      />
                                    )
                                  },
                                  customToolbar: () => <MuiToolbarUploadSubmit
                                    onUpload={() => setShowUploadBenchmarkModal(true)}
                                    onSubmit={() => setShowAnnounceAwardsLotModal(true)}
                                    />
                                }}
                                />
                        }
                        {/* ANCHOR TENDER SKU table */}
                        { isTender && isAwardLevelSelectionASku(awardLevelSelection) && listingQuery.isLoading && <div className="d-flex justify-content-center p-5"><Spinner /></div>}
                        { isTender && isAwardLevelSelectionASku(awardLevelSelection) && listingQuery.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'' + listingLotSkusQuery.error.message}</Alert> }
                        { isTender && isAwardLevelSelectionASku(awardLevelSelection) && listingQuery.isSuccess && <MUIDataTable
                            data={listingLotSkus}
                            columns={columnsSku}
                            options={{
                              pagination: true,
                              jumpToPage: false,
                              rowsPerPage: 100,
                              print: false,
                              search: true,
                              download: true,
                              sort: false,
                              filter: true,
                              viewColumns: true,
                              elevation: 0,
                              components: {
                                TableFilterList: CustomFilterList
                              },
                              onDownload: (buildHead, buildBody, columns, data) => {
                                // Use _csvRender functions to override data...
                                // console.log('colums', columns, data)

                                columns.forEach((col, cidx) => {
                                  if (typeof col._csvRender === 'function') {
                                    data.forEach((row, ridx) => {
                                      row.data[cidx] = col._csvRender(listingLotSkus[ridx])
                                    })
                                  }
                                })
                                return '\uFEFF' + buildHead(columns) + buildBody(data)
                              },
                              rowsSelected: selectedRowIndexes,
                              onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
                                if (selectedRowIndexes.join(',') !== rowsSelected.join(',')) {
                                  setSelectedRowIndexes(rowsSelected)
                                }
                              },
                              setTableProps: () => ({ size: 'small' }),
                              customToolbarSelect: selectedRows => {
                                // defer loading selected SKUs for performance reasons...
                                const getSelectedSkus = () => selectedRows.data.map(d => listingLotSkus[d.dataIndex])
                                return (
                                <SelectToolbarWithoutUnitPrices
                                  onAwardAccept={() => {
                                    announceAwardsSkuMutation.mutate({
                                      listingId,
                                      awardAction: 'accept',
                                      skus: getSelectedSkus()
                                    })
                                    deselectAll()
                                  }}
                                  onAwardDecline={() => {
                                    announceAwardsSkuMutation.mutate({
                                      listingId,
                                      awardAction: 'decline',
                                      skus: getSelectedSkus()
                                    })
                                    deselectAll()
                                  }}
                                  onUpdateBenchmarks={() => {
                                    setSelectedSkus(getSelectedSkus())
                                    setShowUpdateBenchmarkSkuModal(true)
                                    deselectAll()
                                  }}
                                  onSelectAlternativeBid={() => {
                                    setSelectedSkus(getSelectedSkus())
                                    setShowSelectAlternativeBidSkuModal(true)
                                    deselectAll()
                                  }}
                                  onClear={() => {
                                    announceAwardsSkuMutation.mutate({
                                      listingId,
                                      awardAction: 'clear',
                                      skus: getSelectedSkus()
                                    })
                                    deselectAll()
                                  }}
                                  />
                                )
                              },
                              customToolbar: () => <MuiToolbarUploadSubmit
                                onUpload={() => setShowUploadBenchmarkModal(true)}
                                onSubmit={() => setShowAnnounceAwardsSkuModal(true)}
                                />
                            }}
                          />
                        }

                        {/* ANCHOR LOT table */}
                        { !isTender && !isAwardLevelSelectionASku(awardLevelSelection) && listingQuery.isLoading && <div className="d-flex justify-content-center p-5"><Spinner /></div>}
                        { !isTender && !isAwardLevelSelectionASku(awardLevelSelection) && listingQuery.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'' + listingLotSkusQuery.error.message}</Alert> }
                        { !isTender && !isAwardLevelSelectionASku(awardLevelSelection) && listingQuery.isSuccess && <MUIDataTable
                                data={listingLots}
                                columns={columnsLot}
                                options={{
                                  pagination: true,
                                  jumpToPage: false,
                                  rowsPerPage: 100,
                                  print: false,
                                  search: true,
                                  download: true,
                                  sort: false,
                                  filter: true,
                                  viewColumns: true,
                                  elevation: 0,
                                  components: {
                                    TableFilterList: CustomFilterList
                                  },
                                  onDownload: (buildHead, buildBody, columns, data) => {
                                    // Use _csvRender functions to override data...
                                    console.log(columns)
                                    columns.forEach((col, cidx) => {
                                      if (typeof col._csvRender === 'function') {
                                        data.forEach((row, ridx) => { row.data[cidx] = col._csvRender(listingLots[ridx]) })
                                      }
                                    })
                                    return '\uFEFF' + buildHead(columns) + buildBody(data)
                                  },
                                  setTableProps: () => ({ size: 'small' }),
                                  customToolbarSelect: selectedRows => {
                                    // TODO This should be lazy loaded for better performance, see SKU below for example.
                                    const lots = selectedRows.data.map(d => listingLots[d.dataIndex])
                                    const ids1 = selectedLots.map(l => l.id).join(',')
                                    const ids2 = lots.map(l => l.id).join(',')
                                    // only update if the selection has changed...
                                    // console.debug('check', { update: ids1 !== ids2, ids1, ids2 })
                                    if (ids1 !== ids2) setSelectedLots([...lots])
                                    return (
                                    <SelectToolbarWithUnitPrices
                                      onAwardAcceptAndRequestUnitPrice={() => {
                                        announceAwardsLotMutation.mutate({
                                          listingId,
                                          awardAction: 'accept_with_unit_price',
                                          lots
                                        })
                                      }}
                                      onAwardAcceptWithoutUnitPrice={() => {
                                        announceAwardsLotMutation.mutate({
                                          listingId,
                                          awardAction: 'accept_without_unit_price',
                                          lots
                                        })
                                      }}
                                      onAwardDecline={() => {
                                        announceAwardsLotMutation.mutate({
                                          listingId,
                                          awardAction: 'decline',
                                          lots
                                        })
                                      }}
                                      onUpdateBenchmarks={() => setShowUpdateBenchmarkLotModal(true)}
                                      onSelectAlternativeBid={() => setShowSelectAlternativeBidLotModal(true)}
                                      onClear={() => {
                                        announceAwardsLotMutation.mutate({
                                          listingId,
                                          awardAction: 'clear',
                                          lots
                                        })
                                      }}
                                      />
                                    )
                                  },
                                  customToolbar: () => <MuiToolbarUploadSubmit
                                    onUpload={() => setShowUploadBenchmarkModal(true)}
                                    onSubmit={() => setShowAnnounceAwardsLotModal(true)}
                                    />
                                }}
                                />
                        }

                        {/* ANCHOR SKU table */}
                        { !isTender && isAwardLevelSelectionASku(awardLevelSelection) && listingLotSkusQuery.isLoading && <div className="d-flex justify-content-center p-5"><Spinner /></div>}
                        { !isTender && isAwardLevelSelectionASku(awardLevelSelection) && listingLotSkusQuery.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'' + listingLotSkusQuery.error.message}</Alert> }
                        { !isTender && isAwardLevelSelectionASku(awardLevelSelection) && listingLotSkusQuery.isSuccess && <MUIDataTable
                                data={listingLotSkus}
                                columns={columnsSku}
                                options={{
                                  pagination: true,
                                  jumpToPage: false,
                                  rowsPerPage: 100,
                                  print: false,
                                  search: true,
                                  download: true,
                                  sort: false,
                                  filter: true,
                                  viewColumns: true,
                                  elevation: 0,
                                  components: {
                                    TableFilterList: CustomFilterList
                                  },
                                  onDownload: (buildHead, buildBody, columns, data) => {
                                    // Use _csvRender functions to override data...
                                    console.log(columns)
                                    columns.forEach((col, cidx) => {
                                      if (typeof col._csvRender === 'function') {
                                        data.forEach((row, ridx) => { row.data[cidx] = col._csvRender(listingLotSkus[ridx]) })
                                      }
                                    })
                                    return '\uFEFF' + buildHead(columns) + buildBody(data)
                                  },
                                  rowsSelected: selectedRowIndexes,
                                  onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
                                    if (selectedRowIndexes.join(',') !== rowsSelected.join(',')) {
                                      setSelectedRowIndexes(rowsSelected)
                                    }
                                  },
                                  setTableProps: () => ({ size: 'small' }),
                                  customToolbarSelect: selectedRows => {
                                    // defer loading selected SKUs for performance reasons...
                                    const getSelectedSkus = () => selectedRows.data.map(d => listingLotSkus[d.dataIndex])
                                    return (
                                    <SelectToolbarWithoutUnitPrices
                                      onAwardAccept={() => {
                                        announceAwardsSkuMutation.mutate({
                                          listingId,
                                          awardAction: 'accept',
                                          skus: getSelectedSkus()
                                        })
                                        deselectAll()
                                      }}
                                      onAwardDecline={() => {
                                        announceAwardsSkuMutation.mutate({
                                          listingId,
                                          awardAction: 'decline',
                                          skus: getSelectedSkus()
                                        })
                                        deselectAll()
                                      }}
                                      onUpdateBenchmarks={() => {
                                        setSelectedSkus(getSelectedSkus())
                                        setShowUpdateBenchmarkSkuModal(true)
                                        deselectAll()
                                      }}
                                      onSelectAlternativeBid={() => {
                                        setSelectedSkus(getSelectedSkus())
                                        setShowSelectAlternativeBidSkuModal(true)
                                        deselectAll()
                                      }}
                                      onClear={() => {
                                        announceAwardsSkuMutation.mutate({
                                          listingId,
                                          awardAction: 'clear',
                                          skus: getSelectedSkus()
                                        })
                                        deselectAll()
                                      }}
                                      />
                                    )
                                  },
                                  customToolbar: () => <MuiToolbarUploadSubmit
                                    onUpload={() => setShowUploadBenchmarkModal(true)}
                                    onSubmit={() => setShowAnnounceAwardsSkuModal(true)}
                                    />
                                }}
                                />
                        }

                        </div>
                    </div>

                    )
                }

                {/* ANCHOR Debug */}
                { process.env.NODE_ENV !== 'production' && <>
                <div>selectedLotId = <pre>{JSON.stringify(selectedLotId, null, 2)}</pre></div>
                <div>selectedLot = <pre>{JSON.stringify(selectedLot, null, 2)}</pre></div>
                {/* <div>selectedLots = <pre>{JSON.stringify(selectedLots, null, 2)}</pre></div> */}
                {/* { !isAwardLevelSelectionASku(awardLevelSelection) && <div>listingQuery = <pre>{JSON.stringify(listingQuery.data, null, 2)}</pre></div> } */}
                {/* { isAwardLevelSelectionASku(awardLevelSelection) && <div>listingLotSkusQuery = <pre>{JSON.stringify(listingLotSkusQuery.data, null, 2)}</pre></div> } */}
                </>}

              </div>

        {/* ANCHOR Modals */}

        <UploadBenchmarkModal
          listingId={listingId}
          openModal={showUploadBenchmarkModal}
          setOpenModal={setShowUploadBenchmarkModal}
          />

        <UpdateBenchmarkLotModal
          listingId={listingId}
          openModal={showUpdateBenchmarkLotModal}
          setOpenModal={setShowUpdateBenchmarkLotModal}
          selectedLots={selectedLots}
          />
        <UpdateBenchmarkSkuModal
          listingId={listingId}
          openModal={showUpdateBenchmarkSkuModal}
          setOpenModal={setShowUpdateBenchmarkSkuModal}
          selectedSkus={selectedSkus}
          />

        <AnnounceAwardsLotModal
          listingId={listingId}
          openModal={showAnnounceAwardsLotModal}
          setOpenModal={setShowAnnounceAwardsLotModal}
          />
        <AnnounceAwardsSkuModal
          listingId={listingId}
          openModal={showAnnounceAwardsSkuModal}
          setOpenModal={setShowAnnounceAwardsSkuModal}
          />

        <SelectAlternativeBidLotModal
          listingId={listingId}
          openModal={showSelectAlternativeBidLotModal}
          setOpenModal={setShowSelectAlternativeBidLotModal}
          selectedLots={selectedLots}
          />
        <SelectAlternativeBidSkuModal
          listingId={listingId}
          openModal={showSelectAlternativeBidSkuModal}
          setOpenModal={setShowSelectAlternativeBidSkuModal}
          selectedSkus={selectedSkus}
          />

        {/* Listing Notes
        <Modal show={showAdditionalNotesModal} onHide={() => setShowAdditionalNotesModal(false)}>
            <Modal.Body>
                <h5 className="m-0 mb-3">Listing Notes</h5>
                <div className="py-3">
                  {listingQuery.isSuccess && listingQuery.data && typeof listingQuery.data.buyer_notes === 'string' && parse(listingQuery.data.buyer_notes)}
                </div>
                <div className="d-flex justify-content-between mt-5">
                    <Button type="button" onClick={() => setShowAdditionalNotesModal(false)} variant="outline-primary">Close</Button>
                    <Button type="button" onClick={() => vendorNavTo.editListingPage(listingId, { page: CREATE_LISTING_TAB_PAGES.supplements })} variant="primary">Edit</Button>
                </div>
            </Modal.Body>
        </Modal>
        */}
        {/* Terms and Conditions */}
        <Modal show={showTermsAndConditionsModal} onHide={() => setShowTermsAndConditionsModal(false)} size='xl'>
            <Modal.Body>
                <h5 className="m-0 mb-3 ">Terms and Conditions</h5>
                <div className="py-3 border p-3">
                  {listingQuery.isSuccess && listingQuery.data && typeof listingQuery.data.terms_and_conditions === 'string' && parse(listingQuery.data.terms_and_conditions)}
                </div>
                <div className="d-flex justify-content-between mt-5">
                    <Button type="button" onClick={() => setShowTermsAndConditionsModal(false)} variant="outline-primary">Close</Button>
                    <Button type="button" onClick={() => vendorNavTo.editListingPage(listingId, { page: CREATE_LISTING_TAB_PAGES.supplements })} variant="primary">Edit</Button>
                </div>
            </Modal.Body>
        </Modal>

        {/* Sample Photos */}
        <Modal dialogClassName="sample-photo-modal-width" show={showSamplePhotosModal} onHide={() => setShowSamplePhotosModal(false)}>
            <Modal.Body>
                <h5 className="m-0 mb-2">Attachments</h5>
                <div className="py-3">
                  {listingQuery.isSuccess && listingQuery.data && listingQuery.data.sample_photo_files && listingQuery.data.sample_photo_files[0] !== 'undefined' &&
                  <>
                  <Carousel className="carousel slide carousel-dark" id="custom-sample-photo-slider"
                  >
                  {listingQuery.data.sample_photo_files.filter(p => p !== 'undefined').map((filteredPhotoFiles, i) => (
                    <Carousel.Item key={i}>
                    {filteredPhotoFiles.mimetype.slice(0, 5) === 'image'
                      ? <img
                    style={{ objectFit: 'contain', height: 60 + 'vh' }}
                    className="d-block w-100"
                    src={'data:' + filteredPhotoFiles.mimetype + ';base64,' + filteredPhotoFiles.base64content}
                    alt="First slide"
                  />
                      : <div className="py-10">
                  <div className="file-thumbnail mx-auto ">
                    <img
                      className="border h-100 w-100 fit-cover rounded-2"
                      src={
                        filteredPhotoFiles.mimetype.slice(0, 5) ===
                        'image'
                          ? 'data:' +
                            filteredPhotoFiles.mimetype +
                            ';base64,' +
                            filteredPhotoFiles.base64content
                          : filePng
                      }
                      alt=""
                    />
                  </div>
                  <div className="d-flex justify-content-center mt-4">
                    <p>
                      Download:
                      <a
                        href={
                          'data:' +
                          filteredPhotoFiles.mimetype +
                          ';base64,' +
                          filteredPhotoFiles.base64content
                        }
                        download={filteredPhotoFiles.originalname}
                      >
                        {filteredPhotoFiles.originalname}
                      </a>
                    </p>
                  </div>
                </div>}

                  </Carousel.Item>
                  ))}
                </Carousel>

              </>
              }
                </div>
                <div className="d-flex justify-content-between mt-3">
                    <Button type="button" onClick={() => setShowSamplePhotosModal(false)} variant="outline-primary">Close</Button>

                </div>
            </Modal.Body>
        </Modal>

        {/* Participants */}
        <Modal show={showParticipantsModal} onHide={() => setShowParticipantsModal(false)}>
            <Modal.Body>
                <h5 className="m-0 mb-3">Participants</h5>
                <div className="py-3">
                  {listingParticipantsQuery.isSuccess && listingParticipantsQuery.data && (
                  <MUIDataTable
                    columns={[
                      { label: 'Name', name: 'name' },
                      {
                        label: 'Country',
                        name: 'country',
                        options: {
                          customBodyRender: (value) => countriesQuery.isSuccess && getLabelFromArray(countriesQuery.data, value)
                        }
                      }
                    ]}
                    data={listingParticipantsQuery.data.filter(p => p.status !== 'inactive')}
                    options={{
                      pagination: true,
                      jumpToPage: false,
                      print: false,
                      search: 'true',
                      download: false,
                      sort: false,
                      filter: false,
                      viewColumns: false,
                      selectableRows: 'none',
                      elevation: 0,
                      setTableProps: () => ({ size: 'small' })
                    }}
                  />
                  )}
                </div>
                <div className="d-flex justify-content-between mt-5">
                    <Button type="button" onClick={() => setShowParticipantsModal(false)} variant="outline-primary">Close</Button>
                    <Button type="button" onClick={() => vendorNavTo.editListingPage(listingId, { page: CREATE_LISTING_TAB_PAGES.participants })} variant="primary">Manage Participants</Button>
                </div>
            </Modal.Body>
          </Modal>

        {/* Bid Increments */}
        <Modal show={showBiddingIncrementsModal} onHide={() => setShowBiddingIncrementsModal(false)}>
            <Modal.Body>
                <h5 className="m-0 mb-3">{awardLevelSelection === 'award_sku' ? 'Bid Increment' : 'Bid Increments'}</h5>
                <div className="py-3">
                  <MUIDataTable
                    columns={[{ label: 'Bid Range', name: 'range' }, { label: 'Increment', name: 'increment' }]}
                    data={
                      awardLevelSelection === 'award_sku'
                        ? [{ range: '$0+', increment: '$2' }]
                        : [
                            { range: '$0 - $10,000', increment: '$500' },
                            { range: '$10,001 - $25,000', increment: '$1,000' },
                            { range: '$25,001 - $100,000', increment: '$2,500' },
                            { range: '$100,001 - $250,000', increment: '$5,000' },
                            { range: '$250,001 - $500,000', increment: '$10,000' },
                            { range: '$500,001 - $1,000,000', increment: '$25,000' },
                            { range: '$1,000,001+', increment: '$50,000' }
                          ]}
                    options={{
                      pagination: false,
                      jumpToPage: false,
                      print: false,
                      search: false,
                      download: false,
                      sort: false,
                      filter: false,
                      viewColumns: false,
                      selectableRows: 'none',
                      elevation: 0,
                      setTableProps: () => ({ size: 'small' })
                    }}
                  />
                </div>
                <div className="d-flex justify-content-between mt-5">
                    <Button type="button" onClick={() => setShowBiddingIncrementsModal(false)} variant="outline-primary">Close</Button>
                    { _isVendor && <Button type="button" onClick={() => {}} disabled={true} variant="primary">Edit</Button> }
                </div>
            </Modal.Body>
        </Modal>
      </>
}

export default Page
