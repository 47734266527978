
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import React from 'react'
import { Button } from 'react-bootstrap'
import { NOOP } from './utils'

const defaultToolbarStyles = {
  iconButton: {
  }
}
const MuiToolbarUploadSubmit = ({
  onUpload = NOOP,
  onSubmit = NOOP
}) => {
  return (
        <>
          <Tooltip title={'Upload benchmark'}>
            <Button size="sm" variant="outline-primary" className="ml-2" onClick={onUpload} disabled={onUpload === NOOP} >Upload Benchmark</Button>
          </Tooltip>
          <Tooltip title={'Submit'}>
            <Button size="sm" variant="primary" className="ml-2" onClick={onSubmit} disabled={onSubmit === NOOP}>Submit</Button>
          </Tooltip>
        </>
  )
}

export default withStyles(defaultToolbarStyles, { name: 'MuiToolbarUploadSubmit' })(MuiToolbarUploadSubmit)
