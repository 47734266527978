/* eslint-disable camelcase */

import React from 'react'
import { Alert, Button, Modal } from 'react-bootstrap'
import { useGetListingParticipants, useSaveSelectAlternativeBidsSkuMutation } from '../../../components/api'
import { CreateSelect } from '../../../components/Form'
import MUIDataTable from '../../../components/MuiDatatableUtils'
import { skuToDescription } from '../../../components/skuToDescription'
import Spinner from '../../../components/spinner'
import { isUndefOrNull, NOOP } from '../../../components/utils'

/**
 * @typedef {Object} Buyer
 * @property {number} company_id e.g. 4,
 * @property {string} name e.g. "Mobile Zone",
 * @property {string} country e.g. "hk",
 * @property {string} status e.g. "active"
 * @property {string} price e.g. "2000.12"
 */

/**
 *
 * ANCHOR Page
 *
 * @param {Object} props
 * @param {boolean} props.openModal
 * @param {Function} props.setOpenModal
 * @returns
 */
const Page = (props) => {
  // props
  const {
    listingId = '325',
    openModal = true,
    setOpenModal = NOOP,
    selectedSkus = []
  } = props

  const [skus, setSkus] = React.useState([])

  React.useEffect(() => {
    // re-initialise on opening modal...
    if (openModal === true && skus.length === 0 && selectedSkus.length > 0) {
      setSkus(selectedSkus)
    }
  }, [openModal, skus, selectedSkus])

  // required for showing the listing participants...
  const participantsListQuery = useGetListingParticipants(listingId)
  const { data: participants = [] } = participantsListQuery
  /** @type {Object.<string, Buyer} */
  const participantsById = participants.reduce((prev, curr) => {
    prev[curr.company_id] = curr
    return prev
  }, {})

  // query
  const applyAlternativeBidsMutation = useSaveSelectAlternativeBidsSkuMutation()

  // functions
  const onHide = () => {
    // cleanup...
    skus.forEach(sku => delete sku.newBidder)
    // reset...
    setSkus([])
    setOpenModal(false)
    applyAlternativeBidsMutation.reset()
  }

  const getSkuRow = (meta) => {
    return selectedSkus[meta.currentTableData[meta.rowIndex].index]
  }

  const getUpdatedSkus = () => {
    return skus.filter(sku => !isUndefOrNull(sku.newBidder))
  }

  const internalOnSave = () => {
    const updatedSkus = getUpdatedSkus()
    if (updatedSkus.length > 0) {
      applyAlternativeBidsMutation.mutate(updatedSkus.map(sku => {
        const { listingId, listingLotId, listingLotSkuId, highest_company_id: highestBuyerId } = sku
        /** @type {Buyer} */
        const buyer = sku.newBidder
        return { listingId, listingLotId, listingLotSkuId, highestBuyerId, buyerId: buyer.company_id, highestBid: buyer.price }
      }))
    }
  }

  return <>

        <Modal show={openModal} onHide={onHide} size="xl">
            <Modal.Body>
                <h5 className="m-0 mb-3">Select alternative bid</h5>

                <p>Use the dropdown to select an alternative bid.</p>

                <MUIDataTable
                    data={selectedSkus}
                    columns={[
                      { label: 'Lot', name: 'listing_lot_number' },
                      {
                        label: 'Description',
                        name: 'description',
                        options: {
                          customBodyRender: (val, meta) => skuToDescription(getSkuRow(meta))
                        }
                      },
                      { label: 'Grade', name: 'grade' },
                      { label: 'Quantity', name: 'qty' },
                      {
                        label: 'Subtotal',
                        name: 'subtotal',
                        options: {
                          customBodyRender: function(x, meta) {
                            const sku = getSkuRow(meta)
                            return `$${sku.newBidder ? sku.newBidder.priceAsFloat * sku.qty : ''}`
                          }
                        }
                      },
                      {
                        label: 'Alternative Bid',
                        name: 'id',
                        options: {
                          customBodyRender: function(x, meta) {
                            const sku = getSkuRow(meta)
                            const { highest_company_id: highestBuyerId, buyer_bids: buyers, selected_buyer_company_id: selectedCompanyId } = sku
                            if (buyers.length <= 1) {
                              // only zero or one buyer! Can't select alternative buyer!
                              return <i className="text-muted">{buyers.length === 1 ? 'Only one bidder for this item.' : 'No bidders for this item.'}</i>
                            }
                            // TODO Commented out current buyer for now... https://trello.com/c/L2hZPrgO/73-select-alternative-bid-hide-selected-buyer-from-options-menu
                            // const excludeBuyerId = selectedCompanyId === null ? highestBuyerId : selectedCompanyId
                            // buyers = buyers.filter(b => (b.company_id).toString() !== (excludeBuyerId).toString())
                            // map the buyers to something we can display...
                            const buyersOptions = buyers.map(buyer => {
                              /** @type {Buyer} */
                              const tmp = Object.assign(buyer, participantsById[buyer.company_id])
                              tmp.priceAsFloat = parseFloat(tmp.price)
                              tmp.label = `$${tmp.priceAsFloat.toLocaleString()} - ${tmp.name}`
                              tmp.value = (tmp.company_id).toString()
                              return tmp
                            })
                              // sort by highest price first...
                              .sort((a, b) => b.priceAsFloat - a.priceAsFloat)
                            return <CreateSelect
                                className="mb-0"
                                title="Buyer"
                                naked={true}
                                onChange={e => {
                                  /** @type {Buyer} */
                                  const buyer = buyersOptions.find(b => b.value === e.target.value)
                                  if (buyer) {
                                    if (!isUndefOrNull(selectedCompanyId)) {
                                      if (buyer.company_id === selectedCompanyId) {
                                        // if there is a selected company id already, and the new selection matches it... clear the newBidder
                                        delete sku.newBidder
                                        setSkus(ps => [...ps])
                                      } else {
                                        // apply the new bidder...
                                        sku.newBidder = buyer
                                        setSkus(ps => [...ps])
                                      }
                                    } else if (!isUndefOrNull(highestBuyerId) && buyer.company_id === highestBuyerId) {
                                      if (buyer.company_id === highestBuyerId) {
                                        // else if there is a highest company id, and the new selection matches it... clear the newBidder
                                        delete sku.newBidder
                                        setSkus(ps => [...ps])
                                      } else {
                                        // apply the new bidder...
                                        sku.newBidder = buyer
                                        setSkus(ps => [...ps])
                                      }
                                    } else {
                                      // apply the new bidder...
                                      sku.newBidder = buyer
                                      setSkus(ps => [...ps])
                                    }
                                  }
                                }}
                                placeholder="Select a bid"
                                disabled={false}
                                options={buyersOptions}
                                />
                          }
                        }
                      }
                    ]}
                    options={{
                      selectableRows: 'none',
                      pagination: false,
                      jumpToPage: false,
                      rowsPerPage: 15,
                      print: false,
                      search: false,
                      download: false,
                      sort: false,
                      filter: false,
                      viewColumns: false,
                      elevation: 0,
                      setTableProps: () => ({ size: 'small' })
                    }}
                    />

                {/* buttons */}
                <div className="d-flex justify-content-between mt-5">
                    <Button type="button" onClick={onHide} variant="outline-primary">Cancel</Button>
                    <Button type="button" onClick={internalOnSave} variant="primary" disabled={getUpdatedSkus().length === 0 || applyAlternativeBidsMutation.isLoading}>
                        {applyAlternativeBidsMutation.isLoading ? <Spinner /> : <span>Save</span>}
                    </Button>
                </div>

                { applyAlternativeBidsMutation.isSuccess && <Alert variant="success" className="mb-0 mt-3 text-center">Change successful!</Alert> }
                { applyAlternativeBidsMutation.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'Error updating benchmarks. ' + applyAlternativeBidsMutation.error.message}</Alert> }

                {/* { process.env.NODE_ENV !== 'production' && <pre>skus = {JSON.stringify(skus, null, 2)}</pre> } */}
            </Modal.Body>
        </Modal>
      </>
}

export default Page
