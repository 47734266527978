import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import React from 'react'
import { Button } from 'react-bootstrap'

const defaultToolbarStyles = {
  iconButton: {
  }
}
const MuiToolDownloadUnitPrice = ({ onDownloadPrice = () => {}, ...props }) => {
  return (
    <>
      <Tooltip title={'Download Unit Prices'}>
        <Button variant="outline-primary" className="ml-2" onClick={(e) => { onDownloadPrice(e) }}>Download Unit Prices</Button>
      </Tooltip>
    </>
  )
}

export default withStyles(defaultToolbarStyles, { name: 'MuiToolbarUploadSubmit' })(MuiToolDownloadUnitPrice)
