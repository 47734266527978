/* eslint-disable camelcase */

import React from 'react'
import { Alert, Button, Form, InputGroup, Modal } from 'react-bootstrap'
import { useGetListingQuery, useUpdateLotBenchmarks } from '../../../components/api'
import MUIDataTable from '../../../components/MuiDatatableUtils'
import Spinner from '../../../components/spinner'
import { isUndef, NOOP } from '../../../components/utils'

const isBlank = s => s.trim().length === 0

/**
 * Reponsible for display and updating the benchmark.
 * @param {*} props
 * @returns
 */
const PlaceBenchmarkInput = (props) => {
  const {
    listingId,
    listingLotId,
    updatedBenchmarks,
    doUpdateBenchmark
  } = props

  const key = `${listingId}#${listingLotId}`
  // state
  const [amount, setAmount] = React.useState(undefined)

  React.useEffect(() => {
    if (!isUndef(updatedBenchmarks[key])) setAmount(updatedBenchmarks[key].newBenchmark)
  }, [updatedBenchmarks, amount, key])

  // on update...
  const onUpdate = (e) => {
    const { value } = e.target
    if (isBlank(value)) {
      doUpdateBenchmark(listingId, listingLotId, value)
    } else {
      const newBenchmark = Math.round(value)
      if (!isNaN(newBenchmark)) doUpdateBenchmark(listingId, listingLotId, newBenchmark)
    }
  }

  return (<>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text style={{ height: '29px' }}>$</InputGroup.Text>
        </InputGroup.Prepend>
          <Form.Control
          size="sm"
          type="text"
          value={amount}
          onChange={e => onUpdate(e)}
          className='placebenchmark'
          inputMode="numeric"
          />
      </InputGroup>

      {/* { process.env.NODE_ENV !== 'production' && <pre>{JSON.stringify(amount, null, 2)}</pre>} */}
      </>
  )
}

/**
 *
 * !!! Page !!!
 *
 * @param {Object} props
 * @param {boolean} props.openModal
 * @param {Function} props.setOpenModal
 * @returns
 */
const Page = (props) => {
  // props
  const {
    openModal = true,
    setOpenModal = NOOP,
    selectedLots = []
  } = props
  const { listingId = '325' } = props
  const selectedLotIds = selectedLots.map(lot => lot.id)
  //   const { listingId } = getQueryParams(props)

  // query
  // todo - use correct endpoint!
  const updateBenchmarksMutation = useUpdateLotBenchmarks()
  const listingLotQuery = useGetListingQuery({ listingId, isVendor: true })
  const { data: { listing_lots = [] } = {} } = listingLotQuery
  const mapByLotId = listing_lots.reduce((prev, curr) => {
    prev[curr.id] = curr
    return prev
  }, {})

  // state
  /**
   * @type {[Object.<string, import('../../../components/typedefs').EditBenchmarkField>}, function]}
   */
  const [updatedBenchmarks, setUpdatedBenchmarks] = React.useState({})

  /**
   * Stages updates to the benchmarks.
   * @param {*} listingId
   * @param {*} listingLotId
   * @param {*} newBenchmark
   */
  const doUpdateBenchmark = React.useCallback((listingId, listingLotId, newBenchmark) => {
    setUpdatedBenchmarks(ps => {
      const key = `${listingId}#${listingLotId}`
      const { isUpdated, ...prevState } = ps
      const prevEntry = prevState[key]
      const updated = {
        ...prevState,
        [key]: { ...prevEntry, newBenchmark, isUpdated: prevEntry.originalBenchmark !== newBenchmark }
      }
      // materialise the "isUpdated" flag...
      updated.isUpdated = Object.values(updated).some(benchmark => benchmark.isUpdated)
      return updated
    })
  }, [])

  // functions
  const onHide = () => {
    setUpdatedBenchmarks({})
    setOpenModal(false)
    updateBenchmarksMutation.reset()
  }

  const internalOnSave = () => {
    const { isUpdated, ...benchmarks } = updatedBenchmarks
    const validBenchmarks = Object.values(benchmarks).filter(benchmark => benchmark.isUpdated)
    updateBenchmarksMutation.mutate(validBenchmarks)
  }

  React.useEffect(() => {
    // (important!)
    const hasNotYetBeenInitialised = Object.keys(updatedBenchmarks).length === 0
    if (openModal && listingLotQuery.isSuccess && hasNotYetBeenInitialised) {
      // re-initiliase the updateBenchmarks object...
      const map = listingLotQuery.data.listing_lots.reduce((prev, curr) => {
        const { id: listingLotId, benchmark = '' } = curr
        const originalBenchmark = benchmark ? Math.round(benchmark) : ''
        const newBenchmark = originalBenchmark
        /** @type {import('../../../components/typedefs').EditBenchmarkField} */
        prev[`${listingId}#${listingLotId}`] = { listingId, listingLotId, newBenchmark, originalBenchmark, isUpdated: false }
        return prev
      }, { isUpdated: false })
      setUpdatedBenchmarks(map)
    }
  }, [listingLotQuery, listingId, updatedBenchmarks, openModal])

  return <>

        <Modal show={openModal} onHide={onHide}>
            <Modal.Body>
                <h5 className="m-0 mb-3">Edit benchmark</h5>

                { listingLotQuery.isLoading
                  ? <div className="d-flex justify-content-center m-4"><Spinner /></div>
                  : <>
                  <MUIDataTable
                  data={listingLotQuery.data && listingLotQuery.data.listing_lots.filter(lot => selectedLotIds.indexOf(lot.id) !== -1)}
                  columns={[
                    { label: 'Lot', name: 'lot_number' },
                    {
                      label: 'Benchmark',
                      name: 'id',
                      options: {
                        customBodyRender: (listingLotId) => {
                          const benchmark = mapByLotId[listingLotId].benchmark
                          return <PlaceBenchmarkInput
                              listingId={listingId}
                              listingLotId={listingLotId}
                              benchmark={benchmark}
                              updatedBenchmarks={updatedBenchmarks}
                              doUpdateBenchmark={doUpdateBenchmark}
                              />
                        }
                      }
                    }
                  ]}
                  options={{
                    selectableRows: 'none',
                    pagination: false,
                    jumpToPage: false,
                    rowsPerPage: 15,
                    print: false,
                    search: false,
                    download: false,
                    sort: false,
                    filter: false,
                    viewColumns: false,
                    elevation: 0,
                    setTableProps: () => ({ size: 'small' })
                  }}
                  />

          </>}

                {/* buttons */}
                <div className="d-flex justify-content-between mt-5">
                    <Button type="button" onClick={onHide} variant="outline-primary">Cancel</Button>
                    <Button type="button" onClick={internalOnSave} variant="primary" disabled={!updatedBenchmarks.isUpdated}>
                        {updateBenchmarksMutation.isLoading ? <Spinner /> : <span>Save</span>}
                    </Button>
                </div>

                { updateBenchmarksMutation.isSuccess && <Alert variant="success" className="mb-0 mt-3 text-center">Change successful!</Alert> }
                { updateBenchmarksMutation.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'Error updating benchmarks. ' + updateBenchmarksMutation.error.message}</Alert> }

                { process.env.NODE_ENV !== 'production' && <>
                    <pre>selectedLots = {JSON.stringify(selectedLots, null, 2)}</pre>
                    <pre>updatedBenchmarks = {JSON.stringify(updatedBenchmarks, null, 2)}</pre>
                    <pre>listingLotQuery = {JSON.stringify(listingLotQuery, null, 2)}</pre>
                </>}
            </Modal.Body>
        </Modal>
      </>
}

export default Page
