/* eslint-disable camelcase */

import React from 'react'
import { Alert, Button, Form, InputGroup, Modal } from 'react-bootstrap'
import { useUpdateSkuBenchmarks } from '../../../components/api'
import MUIDataTable from '../../../components/MuiDatatableUtils'
import { skuToDescription } from '../../../components/skuToDescription'
import Spinner from '../../../components/spinner'
import { NOOP } from '../../../components/utils'

const isBlank = s => s.trim().length === 0

/**
 * Reponsible for display and updating the benchmark.
 * @param {*} props
 * @returns
 */
const PlaceBenchmarkInput = (props) => {
  const {
    sku,
    onUpdate
  } = props

  // on update...
  const doUpdate = (e) => {
    const { value } = e.target
    if (isBlank(value)) {
      // clear benchmark if value is blank...
      sku.newBenchmark = null
      onUpdate(sku)
    } else {
      const newBenchmark = Math.round(value)
      if (!isNaN(newBenchmark)) {
        if (newBenchmark === sku.benchmark) {
          // clear benchmark if the same value as benchmark...
          delete sku.newBenchmark
          onUpdate(sku)
        } else {
          // otherwise set new benchmark
          sku.newBenchmark = newBenchmark
          onUpdate(sku)
        }
      }
    }
  }

  return (<>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text style={{ height: '29px' }}>$</InputGroup.Text>
        </InputGroup.Prepend>
          <Form.Control
            size="sm"
            type="text"
            value={typeof sku.newBenchmark !== 'undefined' ? sku.newBenchmark : sku.benchmark}
            onChange={e => doUpdate(e)}
            className='placebenchmark'
            inputMode="numeric"
            />
      </InputGroup>

      {/* { process.env.NODE_ENV !== 'production' && <pre>{JSON.stringify(amount, null, 2)}</pre>} */}
      </>
  )
}

/**
 *
 * ANCHOR Page
 *
 * @param {Object} props
 * @param {boolean} props.openModal
 * @param {Function} props.setOpenModal
 * @returns
 */
const Page = (props) => {
  // props
  const {
    openModal = true,
    setOpenModal = NOOP,
    selectedSkus = []
  } = props

  const [skus, setSkus] = React.useState([])

  React.useEffect(() => {
    // re-initialise on opening modal...
    if (openModal === true && skus.length === 0 && selectedSkus.length > 0) {
      setSkus(selectedSkus)
    }
  }, [openModal, skus, selectedSkus])

  const getSkuRow = (meta) => {
    return selectedSkus[meta.currentTableData[meta.rowIndex].index]
  }

  // query
  const updateBenchmarksMutation = useUpdateSkuBenchmarks()

  // state

  // functions
  const onHide = () => {
    // cleanup...
    skus.forEach(sku => delete sku.newBenchmark)
    // reset...
    setSkus([])
    setOpenModal(false)
    updateBenchmarksMutation.reset()
  }

  const getUpdatedSkus = () => {
    return skus // .filter(sku => !isUndefOrNull(sku.newBenchmark)) // N.B. we now want to allow submitting blank/null benchmarks, to clear the value.
  }

  const internalOnSave = () => {
    const updatedSkus = getUpdatedSkus()
    if (updatedSkus.length > 0) {
      updateBenchmarksMutation.mutate(updatedSkus)
    }
  }

  return <>

        <Modal show={openModal} onHide={onHide} size="xl">
            <Modal.Body>
                <h5 className="m-0 mb-3">Edit benchmark</h5>

                  <MUIDataTable
                  data={selectedSkus}
                  columns={[
                    { label: 'Lot', name: 'listing_lot_number' },
                    {
                      label: 'Description',
                      name: 'description',
                      options: {
                        customBodyRender: (val, meta) => skuToDescription(getSkuRow(meta))
                      }
                    },
                    { label: 'Grade', name: 'grade' },
                    { label: 'Quantity', name: 'qty' },
                    {
                      label: 'Benchmark',
                      name: 'id',
                      options: {
                        customBodyRender: (x, meta) => <PlaceBenchmarkInput sku={getSkuRow(meta)} onUpdate={() => setSkus(ps => [...ps])} />
                      }
                    }
                  ]}
                  options={{
                    selectableRows: 'none',
                    pagination: false,
                    jumpToPage: false,
                    rowsPerPage: 15,
                    print: false,
                    search: false,
                    download: false,
                    sort: false,
                    filter: false,
                    viewColumns: false,
                    elevation: 0,
                    setTableProps: () => ({ size: 'small' })
                  }}
                  />

                {/* buttons */}
                <div className="d-flex justify-content-between mt-5">
                    <Button type="button" onClick={onHide} variant="outline-primary">Cancel</Button>
                    <Button type="button" onClick={internalOnSave} variant="primary" disabled={getUpdatedSkus().length === 0 || updateBenchmarksMutation.isLoading}>
                        {updateBenchmarksMutation.isLoading ? <Spinner /> : <span>Save</span>}
                    </Button>
                </div>

                { updateBenchmarksMutation.isSuccess && <Alert variant="success" className="mb-0 mt-3 text-center">Change successful!</Alert> }
                { updateBenchmarksMutation.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'Error updating benchmarks. ' + updateBenchmarksMutation.error.message}</Alert> }

            </Modal.Body>
        </Modal>
      </>
}

export default Page
