
import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { NOOP } from './utils'

export const SelectToolbarWithUnitPrices = ({
  onAwardAcceptWithoutUnitPrice = NOOP,
  onAwardAcceptAndRequestUnitPrice = NOOP,
  onAwardDecline = NOOP,
  onUpdateBenchmarks = NOOP,
  onSelectAlternativeBid = NOOP,
  onClear = NOOP
}) => {
  return (
    <div className="custom-toolbar-select d-flex">

      <Dropdown>
        <Dropdown.Toggle
        variant="outline-primary"
        id="dropdown-basic"
        size="sm"
        className="mr-2 noDropdownArrow"
        >
          <div className="d-flex align-items-center">Award <span className="fas fa-chevron-down ml-2"></span></div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href="#" onClick={onAwardAcceptWithoutUnitPrice} disabled={onAwardAcceptWithoutUnitPrice === NOOP}>Accept without unit price</Dropdown.Item>
          <Dropdown.Item href="#" onClick={onAwardAcceptAndRequestUnitPrice} disabled={onAwardAcceptAndRequestUnitPrice === NOOP}>Accept and request unit price</Dropdown.Item>
          <Dropdown.Item href="#" onClick={onAwardDecline} disabled={onAwardDecline === NOOP}>Decline</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown>
        <Dropdown.Toggle
        variant="outline-primary"
        id="dropdown-basic"
        size="sm"
        className="mr-2 noDropdownArrow"
        >
          <div className="d-flex align-items-center">More Actions <span className="fas fa-chevron-down ml-2"></span></div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href="#" onClick={onUpdateBenchmarks} disabled={onUpdateBenchmarks === NOOP}>Edit benchmark</Dropdown.Item>
          <Dropdown.Item href="#" onClick={onSelectAlternativeBid} disabled={onSelectAlternativeBid === NOOP}>Select alternative bid</Dropdown.Item>
          <Dropdown.Item href="#" onClick={onClear} disabled={onClear === NOOP}>Clear awards</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export const SelectToolbarWithoutUnitPrices = ({
  onAwardAccept = NOOP,
  onAwardDecline = NOOP,
  onUpdateBenchmarks = NOOP,
  onSelectAlternativeBid = NOOP,
  onClear = NOOP
}) => {
  return (
    <div className="custom-toolbar-select d-flex">

      <Dropdown>
        <Dropdown.Toggle
        variant="outline-primary"
        id="dropdown-basic"
        size="sm"
        className="mr-2 noDropdownArrow"
        >
          <div className="d-flex align-items-center">Award <span className="fas fa-chevron-down ml-2"></span></div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href="#" onClick={onAwardAccept} disabled={onAwardAccept === NOOP}>Accept</Dropdown.Item>
          <Dropdown.Item href="#" onClick={onAwardDecline} disabled={onAwardDecline === NOOP}>Decline</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown>
        <Dropdown.Toggle
        variant="outline-primary"
        id="dropdown-basic"
        size="sm"
        className="mr-2 noDropdownArrow"
        >
          <div className="d-flex align-items-center">More Actions <span className="fas fa-chevron-down ml-2"></span></div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href="#" onClick={onUpdateBenchmarks} disabled={onUpdateBenchmarks === NOOP}>Edit benchmark</Dropdown.Item>
          <Dropdown.Item href="#" onClick={onSelectAlternativeBid} disabled={onSelectAlternativeBid === NOOP}>Select alternative bid</Dropdown.Item>
          <Dropdown.Item href="#" onClick={onClear} disabled={onClear === NOOP}>Clear awards</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}
